@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Reset css */
*,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

:root {
  --primary-color: #004f87;
  --white-color: #ffffff;
  --black-color: #000000;
  --secondary-color: #fcd228;
}

a,
a:hover {
  text-decoration: none !important;
}

svg {
  color: var(--black-color);
}

ul,
li,
ol {
  list-style: none;
  padding: 0 !important;
}

.fz-16 {
  font-size: calc(
    14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))
  ) !important;
  line-height: 1.5em;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* btns */
button.btn-design,
a.btn-design {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background: var(--secondary-color);
  color: var(--black-color);
  font-size: 16px;
  text-transform: capitalize;
  padding: 15px 30px !important;
  transition: all 0.4s ease;
  line-height: 1;
  font-weight: 600;
}

button.btn-design:hover,
a.btn-design:hover {
  background: var(--primary-color);
  color: var(--white-color);
  border-color: var(--primary-color);
}

/* display */

.heading-btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.heading-btn_wrapper {
  color: #464255;
}

/* ***** */

h2 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 14px !important;
  font-weight: 500;
  margin-top: 4px;
}

p {
  font-size: 14px !important;
}

/* Loader */
.loader_loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Header Start */
.header {
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.header-left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-left button {
  background-color: transparent;
  border: none;
  cursor: pointer !important;
  width: 22px;
  height: 22px;
  line-height: 1;
}

.header-left button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-left p {
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px !important;
  font-weight: 500 !important;
}

.header-right {
  width: 35px;
  height: 35px;
}

.header-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Start Main container */
.main_container {
  background: #f9f9f9;
  padding: 20px 20px 0;
  min-height: 100vh;
  display: flex;
  gap: 30px;
  width: 100%;
}

.sidebar_wpr {
  max-width: 16%;
  flex: 0 0 16%;
}

.sidebar_wpr.active {
  max-width: 85px;
  flex: 0 0 85px;
}

.ctnt_wpr {
  flex: 0 0 calc(84% - 30px) !important;
  max-width: calc(84% - 30px) !important;
  padding-bottom: 30px;
}

.ctnt_wpr.active {
  flex-grow: 1;
  max-width: 100% !important;
  margin-left: -10px;
}

/* Start Sidebar */
.sidebar {
  padding: 40px 0px 60px 18px;
  width: 100%;
  max-height: 95vh;
  position: fixed;
  background: var(--primary-color);
  box-sizing: border-box !important;
  height: 100%;
  font-weight: 500;
  border-radius: 0px 70px 70px 0px;
  box-shadow: 0 0 10px rgba(0 0 0 / 20%);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 16%;
}

.sidebar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 30px;
  max-width: 150px;
  width: 100%;
}

.sidebar .logo a:last-child {
  width: 25px;
  height: 25px;
  display: none;
}

.sidebar .logo img {
  width: 100%;
}

.sidebar.toggle-active .logo {
  justify-content: flex-start;
  padding: 10px !important;
  margin-bottom: 20px;
}

.sidebar.toggle-active .logo a:first-child {
  display: none;
}

.sidebar.toggle-active .logo a:last-child {
  display: block;
}

.sidebar.toggle-active {
  width: 85px;
  border-radius: 0px 42px 42px 0px;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.sidebarList li a {
  padding: 14px !important;
  border-radius: 10px 0px 0px 10px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  color: var(--white-color);
}

.sidebarList li a span img:last-child {
  display: none;
}

.sidebarList li a:hover span img:first-child,
.sidebarList li a.active span img:first-child {
  display: none;
}

.sidebarList li a:hover span img:last-child,
.sidebarList li a.active span img:last-child {
  display: block;
}

.sidebarList li a:hover,
.sidebarList li a.active,
.sidebarList li a.active #panel1a-header,
.sidebarList li a.hover #panel1a-header {
  background-color: var(--white-color) !important;
  color: var(--primary-color);
}

.sidebarList li img {
  width: 18px;
  height: 20px;
  object-fit: contain;
  object-position: top left;
  margin-right: 15px;
}

.sidebarList li span {
  display: flex;
  line-height: 0;
  align-items: center;
}

.sidebarList li a:hover button {
  color: var(--primary-color);
}

.settings {
  margin-bottom: 5px;
}

.settings .MuiAccordion-root {
  background-color: transparent !important;
  box-shadow: none !important;
  color: var(--white-color);
}

.settings .MuiAccordionSummary-root {
  min-height: fit-content !important;
  padding: 0 !important;
  background-color: transparent;
}

.settings .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  right: 0;
}

.settings .MuiSvgIcon-root {
  color: #fff;
  margin: 0 10px;
}

.settings.active .MuiSvgIcon-root {
  color: #1a3680 !important;
}

.settings .MuiAccordionSummary-content {
  line-height: 1 !important;
  margin: 0 !important;
}

.settings .MuiAccordionSummary-content a {
  flex: 0 0 100%;
  margin: 0;
  align-items: center;
}

.settings .MuiAccordionDetails-root {
  padding: 0 !important;
  margin-top: 12px;
}

.setting-menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 60px;
}

.setting-menu li:last-child {
  margin-top: 0;
}

.setting-menu li a {
  padding: 0 !important;
  position: relative;
  margin: 0;
  font-size: 14px;
  background-color: transparent !important;
}

.setting-menu li a:hover,
.setting-menu li a.active {
  background: transparent !important;
  color: #9f9f9f !important;
}

.setting-menu li a.active {
  color: var(--secondary-color) !important;
}

.setting-menu li a:hover:before,
.setting-menu li a:focus:before {
  background: #9f9f9f !important;
}

.setting-menu li a.active:before {
  background-color: var(--secondary-color) !important;
}

.setting-menu li a::before {
  content: "";
  background-color: var(--white-color);
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: -15px;
}

.sidebar.toggle-active .setting-menu li a::before {
  display: none;
}

.sidebar.toggle-active .setting-menu li {
  margin-bottom: 10px;
}

.sidebar.toggle-active .settings .MuiAccordionDetails-root {
  margin-left: -48px;
}

/* Logout btn  */
.logout_btn > * {
  width: 100% !important;
  height: 100% !important;
}

li a.logout_btn {
  padding: 0 !important;
  flex: 100%;
}

li a.logout_btn button {
  max-width: 100%;
  width: 100%;
  justify-content: flex-start;
  height: 100%;
  max-height: 100%;
  padding: 14px !important;
  font-size: 14px;
  font-weight: 500;
  color: var(--white-color);
  text-transform: capitalize;
}

.box-wrapper {
  padding: 22px;
  background: var(--white-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out 0.1s;
  border-radius: 10px !important;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
}

.box-wrapper canvas {
  max-height: 320px;
  height: 100%;
}

/* Table css */
.table-design .MuiTableHead-root {
  background-color: #f5fbff !important;
  font-size: 16px !important;
  color: var(--black-color) !important;
}

.table-design tbody tr:nth-child(odd) {
  background-color: #fff !important;
}

.table-design tbody tr:nth-child(even) {
  background-color: #fbfbfb !important;
}

.table-design .MuiTableCell-root {
  border: none !important;
}

.table-line {
  margin: 15px 0px;
  border: 1px solid var(--primary-color);
  padding: 20px;
  border-radius: 20px;
}

.tbl_mn {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
}

.table_bx {
  height: auto;
}

.table-design td {
  color: #4e4e4e !important;
}

.table_center td,
.table_center th {
  text-align: start;
}

.table-design th.th-width,
.table-design td.td-width {
  width: 100% !important;
  white-space: wrap !important;
  min-width: 280px !important;
  max-width: 380px !important;
}

.table-design .nowrap {
  white-space: nowrap !important;
}

.cover-img {
  border-radius: 5px;
  width: 200px;
  height: 40px;
  margin: auto;
  overflow: hidden;
  object-fit: contain;
}

.cover-img img {
  width: 100%;
  height: 100%;
}

.profile-img {
  width: 45px;
  height: 45px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  height: 100%;
}

.cover-img-box {
  width: 100%;
  max-height: 140px;
  height: 100%;
  border: 2px dashed var(--primary-color);
  border-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  object-fit: cover;
  position: relative;
  padding: 10px !important;
  z-index: 2;
}

.cover-img-box .pro-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-height: 120px;
}

.table-actions {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px !important;
  cursor: pointer;
}

.table-actions a,
.gallery-actions a,
.edit-btn button {
  background-color: transparent;
  border-radius: 50%;
  border: 1.5px solid var(--primary-color);
  width: 30px !important;
  height: 30px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.gallery-actions a {
  background-color: #fff;
  border: none;
  cursor: pointer !important;
}

.table-actions img,
.gallery-actions img,
.edit-btn img {
  width: 16px;
  height: 16px;
}

.table-actions a img:nth-child(2),
.edit-btn button img:nth-child(2),
.gallery-actions a img:nth-child(2) {
  display: none;
}

.table-actions a:hover,
.edit-btn button:hover,
.gallery-actions a:hover {
  background-color: var(--primary-color);
}

.table-actions a:hover img:first-child,
.gallery-actions a:hover img:first-child,
.edit-btn button:hover img:first-child {
  display: none;
}

.table-actions a:hover img:nth-child(2),
.gallery-actions a:hover img:nth-child(2),
.edit-btn button:hover img:nth-child(2) {
  display: block !important;
}

.gallery-actions.ticket-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

/* Table search */
.search {
  max-width: 300px;
  width: 100%;
  margin-left: auto;
}

.table_srch {
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 0.8);
  background: rgba(217, 217, 217, 0.1);
  width: 100%;
}

.table_srch .MuiInputBase-root {
  padding: 0 !important;
}

.table_srch > div {
  width: 100%;
}

.table_srch input {
  flex: 0 0 80%;
  max-width: 80%;
  padding: 8px 10px;
}

.table_srch svg {
  transform: rotate(355deg);
  position: absolute;
  right: 20px;
  fill: var(--primary-color);
}

/* Group Detail */
.inpt_bx {
  margin-bottom: 10px;
}

.inpt_bx h6,
.sm_label {
  margin-bottom: 10px !important;
  color: var(--black-color);
  font-size: 18px;
  font-weight: 400;
}

.inpt_bx input,
.inpt_bx textarea,
.text_bx {
  border-radius: 15px !important;
  border: 1px solid #a9a9a9;
  padding: 15px !important;
}

.inpt_bx textarea {
  width: 100%;
}

.inpt_bx input:hover,
.inpt_bx input:focus-visible,
.inpt_bx input:active,
.inpt_bx textarea:hover,
.inpt_bx textarea:focus-visible,
.inpt_bx textarea:active {
  border: 1px solid var(--primary-color) !important;
  outline: none !important;
}
.info-box .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.info-box svg {
  color: #004f87;
}
/* Chrome, Safari, Edge, Opera */
/* For hiding input type no. arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text_bx {
  padding: 16.5px 14px;
  font-size: 16px !important;
  min-height: 56px;
}

.inpt_bx fieldset {
  border: 0;
}

.prfl_img {
  width: 240px;
  position: relative;
  margin: auto;
  height: 240px;
  border-radius: 50%;
  border: 2px dashed var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.prfl_img .pro-img {
  width: 200px;
  display: flex;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0 0 0 / 30%);
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.prfl_img label,
.cover-img-box label {
  position: absolute;
  bottom: 14%;
  right: 33px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-color);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0 0 0 / 30%);
  cursor: pointer;
}

.cover-img-box label {
  bottom: -15%;
  right: 15px;
}

.prfl_img .edit-icon,
.cover-img-box .edit-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-icon img {
  width: 100%;
  height: 100%;
}

.grp_dtl h6 {
  font-weight: 600;
  font-size: 18px;
}

.grp_dtl p {
  color: #adb5bd;
}

.pwd_form input {
  padding: 16px 20px;
}

.pwd_form .inpt_bx *:after,
.pwd_form .inpt_bx *::before {
  display: none;
}

/* Login */
.lgn_mdl {
  width: 100% !important;
}

.lgn_mdl_wpr {
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 540px;
  height: 100%;
}

.lgn_mdl_wpr > div {
  box-shadow: 0 0 10px rgba(0 0 0 / 30%);
  border-radius: 30px;
  display: flex;
  width: 100%;
}

.lgn_left {
  flex: 0 0 50%;
  width: 100%;
  height: 100%;
  position: relative;
}

.lgn_left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.lgn_right {
  flex: 0 0 50%;
  width: 100%;
  display: flex;
  align-items: center;
}
.lgn_right p {
  opacity: 0.8;
}

.login-btn {
  background: var(--primary-color) !important;
  padding: 10px !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-size: 17px !important;
  width: 25%;
  font-weight: 500 !important;
  text-align: center;
  transition: all 0.3s ease !important;
  text-transform: capitalize !important;
}

.login-btn:hover {
  background: #0d609c;
  color: #fff;
}

.frgt-pass {
  color: var(--primary-color) !important;
  font-size: 14px;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.frgt-pass:hover {
  text-decoration: underline !important;
}

.lgn_mdl_wpr h4 {
  font-size: 28px !important;
  margin-bottom: 40px;
  color: var(--primary-color);
}

.lgn_mdl_wpr .MuiSvgIcon-root {
  color: var(--primary-color);
}

.lgn_mdl .logo {
  text-align: center;
}

.lgn_mdl .logo img {
  max-width: 160px;
  width: 100%;
  margin-bottom: 40px;
}

.lgn_mdl h4 {
  text-align: center;
  font-weight: 500;
}

.lgn_mdl fieldset {
  border: 0;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0 0 0 / 20%);
}

.lgn_mdl_wpr button {
  color: #fff !important;
}

a.logout_btn button {
  border: 0 !important;
  padding: 0 !important;
  color: var(--bs-black);
}

/* Modal CSS */
.custom_modal {
  padding: 40px !important;
}

.custom_modal div[role="dialog"] {
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.custom_modal div[role="dialog"] p {
  color: var(--black-color);
  font-size: 20px !important;
}

.custom_modal .btn_wpr {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 30px;
}

.custom_modal .btn_wpr button,
.custom_modal .btn_wpr a {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  font-weight: 500;
  color: var(--white-color);
  text-transform: capitalize;
  padding: 10px 30px;
  max-width: 140px;
  width: 100%;
  font-size: 16px;
  transition: all 0.4s ease;
}

.custom_modal .btn_wpr button:hover,
.custom_modal .btn_wpr a:hover {
  background-color: #0d609c;
}

.custom_modal .btn_wpr button:last-child,
.custom_modal .btn_wpr a:last-child {
  background-color: var(--white-color);
  color: var(--primary-color);
}

.custom_modal .btn_wpr button:hover:last-child,
.custom_modal .btn_wpr a:hover:last-child {
  background-color: #0d609c;
  color: var(--white-color);
}

.modal-img {
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.modal-img img {
  width: 25px;
  height: 25px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
/* Chrome, Edge, and Safari */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #00000067;
  border-radius: 10px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.sidebar {
  scrollbar-width: none;
  scrollbar-color: #ff0505 #ffffff;
}

/* Chrome, Edge, and Safari */
.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar::-webkit-scrollbar-track {
  background: #ffffff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #f5fbff;
  border-radius: 0px;
  border: 0px solid #ffffff;
}

.table-responsive {
  scrollbar-width: thin !important;
}

/* privacy policy */
.policy-box {
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding-top: 40px;
}

.policy-box::after {
  content: "";
  width: 150%;
  height: 270px;
  background-color: #eff8ff;
  position: absolute;
  top: -175px;
  left: 50%;
  right: 0;
  z-index: -1;
  border-radius: 50%;
  transform: translateX(-50%);
}

.policy-box .actions button {
  color: var(--black-color);
  padding: 8px 20px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  text-transform: capitalize;
}

.policy-box .actions button:hover {
  background-color: var(--secondary-color);
}

.policy-box .actions button:last-child {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.privacy-policy {
  max-width: 850px;
  width: 100%;
  max-height: 580px;
  height: 100%;
  overflow-y: auto;
  margin: auto;
}

/* Contact Us */
.contact-top {
  text-align: center;
  margin-bottom: 50px;
}

.contact-top h3 {
  color: var(--primary-color);
  font-size: 22px !important;
  font-weight: 500;
}

.contact-top p {
  color: #4e4e4e;
  font-size: 18px !important;
  font-weight: 500;
}

.contact-box.policy-box::after {
  width: 115%;
  top: -80px;
}

/* Contact Cards */
.contact-card {
  max-width: 60%;
  width: 100%;
  margin: 60px auto;
  background-color: #fffcee;
  padding: 30px 50px;
}

.contact-card .card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.contact-card .card-head h3 {
  color: var(--primary-color);
  font-size: 22px !important;
  font-weight: 500;
}

.contact-cards {
  display: flex;
  gap: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.contact-cards h3 {
  color: var(--black-color);
  font-size: 20px !important;
  font-weight: 500;
}

.contact-cards p {
  color: #4e4e4e;
  font-size: 16px !important;
  font-weight: 400;
  max-width: 400px;
  width: 100%;
}

/* Tabs btns  */
.tabs-btn button {
  background-color: #ebebeb;
  padding: 15px;
  color: #494848;
  font-size: 16px;
  line-height: normal;
  text-transform: uppercase;
  border: 1px solid #a9a9a9;
}

.tabs-btn button.active {
  background-color: var(--white-color);
  color: var(--primary-color);
  font-weight: 600;
  border: 1px solid var(--primary-color);
  border-bottom: 0;
  position: relative;
}

.tabs-btn button.active::after {
  content: "";
  background-color: var(--primary-color);
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* Trip detail  */
.trip-box {
  padding: 0 20px;
}

.trip-box .user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.trip-box .user-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background: #e5e5e5;
}

.trip-box .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trip-box .user-detail {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background: #fbfbfb;
  text-align: center;
  padding: 20px 30px;
  width: 100%;
  margin-top: 30px;
}

.trip-box .user-detail h4 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.trip-box .user-detail p {
  color: #4e4e4e;
  font-size: 16px !important;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.trip-table .tbl_mn {
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: var(--white-color);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.04);
}

.trip-table .tbl_mn h3 {
  color: var(--primary-color);
  font-size: 24px !important;
  font-weight: 500;
  line-height: normal;
  padding: 20px;
}

input::file-selector-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.trip_wrapper {
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.04);
  padding: 40px;
}

.ticket-info h4 {
  color: var(--primary-color);
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.ticket-card {
  border-radius: 15px;
  border: 1px solid #f0f0f0;
  background: #f3f3f3;
  margin-top: 20px;
  padding: 20px 45px 20px 30px;
  position: relative;
}

.ticket-card .card-info {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.ticket-card h5 {
  color: var(--black-color);
  font-size: 20px !important;
  font-weight: 500;
  margin-bottom: 12px;
}

.card-info h4 {
  color: #4e4e4e;
  font-size: 15px !important;
  font-weight: 400;
  margin-top: 15px;
}

.ticket-card p {
  color: #4e4e4e;
  font-size: 14px !important;
  margin-bottom: 12px;
}

.ticket-card h6 {
  color: var(--black-color);
  font-size: 16px !important;
  font-weight: 500;
  opacity: 0.8;
}

.ticket-card h3 {
  color: var(--black-color);
  font-size: 25px !important;
}

.ticket-card button {
  border-radius: 5px;
  background: var(--secondary-color);
  padding: 10px 15px;
  border: none;
  color: var(--black-color);
  font-weight: 500;
  font-size: 15px;
  min-width: 100px;
}
.ticket-card button.btn-design {
  min-width: 100px;
  font-weight: 500;
  font-size: 15px;
}
.ticket-info .info-bg {
  border-radius: 15px;
  border: 1px solid #f0f0f0;
  background: var(--white-color);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);
  margin-top: 20px;
}

.ticket-depart,
.ticket-return {
  padding: 20px !important;
  padding-bottom: 0px !important;
}

.ticket-info .info-bg li span img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.icon-sm {
  width: 9px !important;
  height: 9px !important;
}
.icon-md {
  width: 14px !important;
  height: 15px !important;
}
.ticket-depart li,
.ticket-return li {
  border-bottom: 1px solid #d9d9d9;
  padding-top: 15px !important;
}

.ticket-depart li:last-child,
.ticket-return li:last-child {
  border-bottom: none;
}

.ticket-return {
  border-top: 2px dashed #d9d9d9;
  margin-top: 40px;
}

.ticket-depart h6,
.ticket-return h6 {
  color: var(--primary-color);
  font-size: 14px;
}

.trip-info-hdg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto 20px;
  gap: 15px;
}

.trip-info-hdg h6 {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0;
  flex: 0 0 35%;
}
.trip-info-hdg h6:nth-child(1) {
  text-align: start;
}
.trip-info-hdg h6:nth-child(3) {
  text-align: end;
}
.flight-icon {
  flex-grow: 1;
  display: inline-flex;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.flight-icon:after {
  position: absolute;
  width: 100%;
  border: 1px dashed #a9a9a947;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.trip-dtl {
  text-align: center;
  font-size: 16px;
  color: #4e4e4e;
  margin-bottom: 26px;
}

.blue-txt {
  color: var(--primary-color);
  font-weight: 400;
}

.ticket-return h4,
.ticket-depart h4 {
  color: var(--black-color);
  font-size: 18px;
}

.ticket-depart p,
.ticket-return p {
  font-size: 15px !important;
  color: #4e4e4e;
  margin-bottom: 10px;
}
.bill-info {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 30px;
  border-top: 2px dashed #d9d9d9;
  padding: 20px 0 40px;
}
.bill-info h3 {
  color: var(--primary-color);
  font-size: 18px !important;
  font-weight: 500;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.bill-info h3 span:first-child {
  font-size: 15px;
}
.pay-btns {
  display: flex;
  justify-content: end;
  gap: 15px;
}
.pay-btns button {
  padding: 8px 15px;
  border: none;
  font-size: 15px;
  min-width: 120px;
}

.pay-btns span img {
  filter: invert(1);
  margin-right: 5px;
  transition: all 0.4s ease;
}
.pay-btns a:hover span img {
  filter: invert(0) !important;
}
a.dwn-btn span img {
  filter: invert(0) !important;
  margin-right: 0px;
}
.trip-detail {
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fbfbfb;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.04);
  padding: 20px;
  width: 100%;
}
.trip-detail .date-field {
  display: flex;
}
.trip-detail .date-field button {
  padding: 0;
  margin: 0;
}
.trip-detail .date-field svg {
  width: 20px;
  height: 20px;
  color: #d32f2f;
}
.trip-detail h3 {
  color: var(--primary-color);
  font-size: 24px !important;
  font-weight: 500;
}

.trip-detail ul li {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  padding: 12px 0 !important;
}

.trip-detail ul li h6 {
  margin: 0;
  flex: 0 0 30%;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px !important;
  font-weight: 500;
}

.trip-detail ul li p {
  margin: 0;
  flex: 0 0 70%;
  color: #4e4e4e;
  font-size: 14px !important;
}

.drag-file {
  position: relative;
}

.drag-file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 15px;
}

.drag-file .drag-box {
  border-radius: 15px;
  border: 2px dashed var(--primary-color);
  background: rgba(217, 217, 217, 0.15);
  width: 100%;
  padding: 10px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.drag-box img {
  width: 65px;
  height: 65px;
}

.drag-box p {
  color: #000;
  font-size: 16px !important;
}

.gallery-btn {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;
}

.gallery-btn button {
  border-radius: 5px;
  border: none;
  background: #ebebeb;
  padding: 10px 15px;
  max-width: 160px;
  width: 100%;
  color: var(--black-color);
  font-size: 15px !important;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.gallery-btn button.active {
  background: var(--primary-color);
  color: var(--white-color);
}

.gallery-btn button img {
  width: 23px;
  height: 23px;
}

.gallery-btn button.active img {
  filter: brightness(233);
}

.gal-data p {
  font-size: 16px !important;
  margin-top: 40px;
}

.gal-img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 8px;
}

/* Image list */
.MuiImageList-root {
  margin-top: 50px;
}

.MuiImageListItem-root {
  height: 250px !important;
  border-radius: 10px !important;
  overflow: hidden;
}

.MuiImageListItemBar-actionIcon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.receipt-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.05);
  padding: 40px;
  margin: 0 auto;
  width: 60%;
}

.receipt-box h4 {
  color: var(--primary-color);
  font-size: 20px !important;
  font-weight: 500;
}

.receipt-box #pdf-controls {
  background-color: #fff;
}

.receipt-box .receipt-img {
  width: 100%;
  height: 500px;
  margin: 30px auto 50px;
}

.receipt-img img {
  width: 100%;
  height: 100%;
}

.receipt-btns button {
  background-color: #10a728;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 8px 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.receipt-btns button:hover {
  background-color: #1ec039;
}

.receipt-btns button:last-child {
  background-color: #e73d3d;
}

.receipt-btns button:last-child:hover {
  background-color: #c64343;
}

/* Chat Page */
.chat-bx-otr {
  display: flex;
}

.chat-bx-user {
  flex: 0 0 350px;
  box-shadow: 4px 0px 13px rgba(0 0 0 / 10%);
  border-radius: 10px 0 0 10px;
  padding: 20px;
  height: 800px;
  background-color: #fff;
  max-width: 350px;
  width: 100%;
}
.chat-srch {
  box-shadow: 0 0 10px rgba(0 0 0 / 10%);
  padding: 13px 16px;
  border-radius: 10px;
  margin: 20px 0;
  position: relative;
  display: flex;
  gap: 10px;
}

.chat-srch input[type="text"] {
  flex-grow: 1;
  border: 0;
  box-shadow: 0;
  outline: 0;
}

.chat-srch svg {
  opacity: 0.5;
}

.chat-usr-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  margin-top: 30px !important;
  height: 80%;
  padding: 0 10px !important;
  overflow-x: hidden;
}

.chat-usr-list::-webkit-scrollbar {
  width: 2px;
}

.chat-usr-list-item {
  display: flex;
  gap: 10px;
  position: relative;
  padding: 6px 40px 6px 6px !important;
  cursor: pointer;
}

.chat-usr-list-item.active {
  background: #0f82ff36;
  border-radius: 8px;
}

.chat-usr-img {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  border-radius: 50%;
  overflow: hidden;
}

.chat-usr-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.chat-hdr {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 0px 10px 0 0;
  padding: 8px 25px;
  gap: 14px;
  color: #fff;
}
.chat-usr-ctnt {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  width: 100%;
  flex: 0 0 90%;
}
.usr-name {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2em;
  padding-left: 15px;
}
.usr-name.source,
.usr-name.destination {
  position: relative;
}
.usr-name.source::before,
.usr-name.destination::before {
  content: "";
  position: absolute;
  background-color: white;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 2px;
  left: 0px;
  border: 2px solid rgba(206, 8, 8, 0.894);
}
.usr-name.destination::before {
  border-color: #0e7605;
}
.last-msg {
  color: #a9a9a9;
  font-size: 12px;
  display: block;
}
.usr-name.holiday,
.usr-name.name,
.last-msg.holiday {
  padding-left: 0px;
  text-transform: capitalize;
}
.usr-name.name {
  font-size: 15px !important;
}
.last-msg.unread {
  color: #27cb39;
  font-weight: 500;
}
.time {
  position: absolute;
  color: #6c6c6c;
  font-size: 13px;
  right: 6px;
  top: 7px;
}
.chat-time {
  position: absolute;
  right: 0;
  top: 0;
  color: #a9a9a9;
  font-size: 12px;
}

.chat-bx {
  height: 800px;
  box-shadow: 7px 0px 10px rgba(0 0 0 / 10%);
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat-hdr {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 0px 10px 0 0;
  padding: 15px 30px;
  gap: 15px;
  color: #fff;
}

.chat-img {
  max-width: 60px;
}

.chat-info h5 {
  font-size: 22px;
  font-weight: 400;
}

.chat-info h6 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.chat-msg-wpr {
  padding: 30px 40px;
  flex-grow: 1;
  overflow-y: auto;
  background: #f8f8f8;
}

.chat-msg-wpr::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.chat-msg-wpr::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--secondry-color);
}

.msg-img {
  filter: drop-shadow(2px 4px 6px #e4e4e4);
  width: 40px;
  overflow: hidden;
  height: 40px;
  border-radius: 50%;
}

.recieve-msg {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.msg-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px !important;
  max-width: 60%;
}

.msg-list li {
  background: #0f82ff;
  padding: 12px 20px !important;
  color: #fff;
  font-size: 14px;
  border-radius: 30px 30px 30px 0;
  max-width: 400px;
}

.msg-list li:last-child {
  border-radius: 30px 30px 30px 0px;
}

.msg-list img {
  border-radius: 20px;
  width: 180px;
  height: 180px;
}
.msg-list .p-5 {
  padding: 7px !important;
}
.send-msg {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 10px;
}
.send-msg .msg-img {
  align-self: flex-start;
}
.send-msg .msg-img .img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.send-msg .msg-list {
  align-items: flex-end;
  margin-left: auto !important;
}

.send-msg .msg-list li {
  border-radius: 30px 30px 0 30px;
  background-color: #001835;
  display: flex;
  flex-direction: column;
}
.msg-list h6 {
  font-weight: 400 !important;
  font-size: inherit;
  margin: 0 !important;
  word-wrap: break-word;
  overflow: auto;
  white-space: break-spaces;
}
.msg-list time {
  font-size: 12px;
  text-align: end;
  margin-bottom: -10px;
  font-weight: 500 !important;
}
.msg-list.usr-msg time {
  text-align: start;
}
.msg-list.usr-msg {
  margin-bottom: 10px !important;
}
.msg-list.admin {
  margin-left: 50px;
  margin-bottom: 10px !important;
}
.chat-date {
  display: flex;
  justify-content: center;
  margin: auto 20px;
}
.chat-date p {
  background-color: #cce5ff;
  padding: 3px 10px;
  border-radius: 8px;
  font-size: 13px;
}
.chat-ftr {
  background: #fff;
  padding: 0 10px;
}
.chat-ftr .file-preview {
  padding: 10px 20px;
  border-bottom: 1px solid #0a568cad;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.file-preview.show {
  max-height: 1000px;
  transition: max-height 0.3s ease;
}

.file-preview .img-preview,
.file-preview .doc-preview {
  background: #cce5ff;
  width: 180px;
  height: 180px;
  border-radius: 10px;
  position: relative;
  padding: 5px;
}
.file-preview .img-preview button,
.file-preview .doc-preview button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #004f87;
  border-radius: 50%;
  padding: 1px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11px;
}
.img-preview div,
.doc-preview div {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.img-preview div img,
.doc-preview div img {
  height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  transform: none !important;
}
.doc-preview div img {
  height: 20%;
  max-width: 20%;
}
.doc-preview p {
  word-wrap: break-word;
  white-space: pre-line;
  max-height: 98px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-ftr .input-box {
  position: relative;
  padding: 10px 20px;
  background: #fff;
  display: flex;
  gap: 10px;
  border-radius: 0 0 10px 0;
}
.chat-ftr span {
  max-width: 40px;
  max-height: 40px;
  background-color: transparent;
  border-radius: 50%;
  overflow: hidden;
}
.chat-ftr .input-box span img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-ftr input[type="text"] {
  background: transparent;
  border: 0;
  flex-grow: 1;
}

.chat-ftr input[type="text"]::placeholder {
  color: #a6adb5;
}

.chat-ftr input[type="text"]:focus {
  outline: 0;
  box-shadow: 0;
}

.send-files {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.send-files input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  cursor: pointer !important;
}
.send-files .MuiSvgIcon-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: #0a568c;
  transform: rotate(-45deg);
  z-index: 4;
}
.chat-ftr button {
  border: none;
}
.chat-ftr img {
  max-width: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0.1s;
}

.chat-ftr img:hover {
  transform: rotate(45deg);
}

.chat-sec-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chat-sec-img img {
  max-width: 450px;
}

/* Dashboard */
.db-box .row .db-card {
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
  padding: 20px;
  height: 100%;
  max-height: 420px;
  overflow-y: hidden;
}

.db-box h3 {
  color: var(--primary-color);
  font-size: 20px !important;
  font-weight: 500;
}

.db-card .tbl_mn {
  max-height: 350px;
  height: 100%;
  scroll-behavior: auto;
}

.db-card .tbl_mn::-webkit-scrollbar {
  width: 0px !important;
}

.management-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.man-card {
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  padding: 12px;
  position: relative;
  flex: 0 0 calc(100% / 4 - 30px);
}

.man-card::after {
  content: "";
  width: 60px;
  height: 60px;
  border: 8px solid var(--primary-color);
  border-top: 0;
  border-right: 0;
  background-color: transparent;
  border-radius: 0 0 0 15px;
  position: absolute;
  bottom: -4px;
  left: -4px;
}

.man-card > div {
  background-color: #e3e3e3;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  gap: 20px;
  height: 100%;
}

.man-card .man-img {
  width: 50px;
  height: 50px;
  align-self: center;
}

.man-card p {
  color: var(--black-color);
  font-size: 15px !important;
  margin-bottom: 8px;
  font-weight: 500;
}

.man-card h2 {
  color: var(--primary-color);
  font-size: 28px !important;
  font-weight: 500;
  margin-bottom: 0 !important;
  line-height: 1;
}

.db-box td {
  font-size: 15px !important;
  padding: 16px !important;
}

.db-box .cover-img {
  width: 118px;
  height: 35px;
}

.db-box .cover-img img {
  width: 100%;
}

.user_table tr td,
.user-table-lg tr td {
  vertical-align: middle !important;
}

table tbody tr td {
  vertical-align: top !important;
  word-break: break-word;
}

.user_table tr th:nth-child(1),
.user_table tr td:nth-child(1) {
  min-width: 120px !important;
}

.offer_table tr th:nth-child(1),
.offer_table tr td:nth-child(1) {
  min-width: 150px !important;
}

.offer_table tr th:nth-child(2),
.offer_table tr td:nth-child(2) {
  min-width: 180px !important;
  max-width: 200px !important;
  white-space: break-spaces;
}

.offer_table tr th:nth-child(3),
.offer_table tr td:nth-child(3) {
  min-width: 300px !important;
  max-width: 350px !important;
  width: 100% !important;
  white-space: break-spaces;
}

.trip_table tr th:nth-child(4),
.trip_table tr td:nth-child(4) {
  min-width: 120px !important;
}

.holiday_table tr th:nth-child(1),
.holiday_table tr td:nth-child(2) {
  min-width: 180px !important;
}

.holiday_table tr th:nth-child(2),
.holiday_table tr td:nth-child(2) {
  min-width: 160px !important;
  max-width: 280px !important;
  width: 100% !important;
  white-space: break-spaces;
}

.holiday_table tr th:nth-child(3),
.holiday_table tr td:nth-child(3) {
  min-width: 300px !important;
  max-width: 350px !important;
  width: 100% !important;
  white-space: break-spaces;
}

.offer_table-lg thead tr th:nth-child(2),
.offer_table-lg tbody tr td:nth-child(2) {
  max-width: 200px !important;
  white-space: break-spaces !important;
  width: 25%;
}

.offer_table-lg thead tr th:nth-child(3),
.offer_table-lg tbody tr td:nth-child(3) {
  max-width: 180px !important;
  white-space: break-spaces !important;
  width: 40% !important;
}

.pie-chart canvas {
  max-width: 300px !important;
  width: 100%;
  margin: 20px auto;
}

.db-card .show-link {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
}

.db-card .show-link:hover {
  text-decoration: underline !important;
}

/* Dropdown Mui */
.chart-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-head .MuiButtonBase-root {
  color: var(--black-color) !important;
}

.chart-head .dropdown-toggle::after {
  display: none !important;
}

.chart-head .dropdown-menu {
  padding: 10px 0 !important;
  font-size: 14px !important;
  color: var(--black-color) !important;
  background-color: var(--white-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px !important;
  min-width: 130px !important;
  font-family: inherit !important;
}

.chart-head .btn,
.chart-head .btn:hover .chart-head .btn:focus-visible,
.chart-head .btn:first-child:active {
  background: transparent !important;
  outline: none !important;
  border: none !important;
  padding: 0 !important;
}

.chart-head .dropdown-item.active,
.chart-head .dropdown-item:active {
  background-color: var(--secondary-color);
}

.chart-head .dropdown-item:hover,
.chart-head .dropdown-item:focus {
  background-color: #8bb9da;
  color: var(--black-color) !important;
}

.w-40 {
  width: 40%;
}

.btn-receipt,
.btn-unpaid,
.btn-paid,
.btn-danger {
  text-transform: capitalize !important;
}

.btn-receipt:hover {
  background-color: var(--secondary-color) !important;
}

.btn-unpaid {
  background-color: #fcd228 !important;
  color: #000 !important;
  cursor: default !important;
}

.btn-danger {
  background-color: #ff0505 !important;
  color: #fff !important;
  cursor: default !important;
}

.btn-paid {
  background-color: #1ec039 !important;
  cursor: default !important;
  color: #fff !important;
}
a.dwn-btn {
  background-color: #0f82ff !important;
  border: none !important;
  color: #fff !important;
  padding: 15px 20px !important;
}
a.dwn-btn span {
  margin-right: 10px;
}
.back-btn a {
  color: var(--white-color);
  background-color: var(--primary-color) !important;
  padding: 8px 20px !important;
  border-radius: 5px;
  border-color: var(--primary-color);
}

.back-btn a:hover {
  background-color: #0d609c;
  color: var(--white-color) !important;
}

.inpt_bx .MuiSvgIcon-root {
  color: var(--primary-color) !important;
}

.inpt_bx .MuiFormControl-root .MuiInputAdornment-root {
  position: absolute;
  right: 25px;
}

.MuiInputLabel-root.MuiInputLabel-formControl {
  background-color: #fff;
}

.input-error input,
.input-error textarea {
  border: 1px solid d32f2f !important;
}

form .MuiFormHelperText-root.Mui-error,
.MuiFormHelperText-root,
form .error-text {
  color: #d32f2f !important;
  font-size: 13px !important;
  margin: 0 !important;
  padding: 5px 0 0 5px !important;
}

textarea.error-border,
.error-border .MuiInputBase-input {
  border: 1px solid #d32f2f !important;
}

.inpt_bx .Mui-error input {
  border: 1px solid #d32f2f !important;
}

.pwd-input.pass-error .MuiFormLabel-root,
.pwd-input.pass-error input[type="text"] {
  color: #d32f2f !important;
}

.pass-err input[type="password"]::placeholder,
.pass-err label,
.pass-err legend span {
  color: #d32f2f !important;
}

.toast-message {
  text-transform: capitalize;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

p.login-link {
  font-size: 15px !important;
  font-weight: 500;
}

p.login-link span {
  color: #004f87;
}

p.login-link span:hover {
  text-decoration: underline;
}

@media (max-width: 1400px) {
  .trip-box .user-detail {
    width: 50%;
  }
}

/* Feedback Card */
.feedback-card {
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0 0 0 / 25%);
}

.rating-emoji {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.rating-emoji label svg {
  font-size: 40px;
  margin-right: 20px;
}

.cmt-bx p {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #004f87;
  padding: 10px;
  margin-top: 6px;
  margin-bottom: 15px;
  resize: none;
  min-height: 120px;
  word-wrap: break-word;
}

.datepicker {
  width: 100%;
}

.datepicker.error-border {
  border-color: #c64343;
}

.MuiFormControlLabel-label {
  color: var(--black-color);
  font-size: 18px !important;
  font-weight: 400 !important;
}

.form_wrapper .MuiFormControl-root {
  width: 100%;
}

/* Offer card */
.offer-card {
  margin-top: 25px;
  padding: 25px 30px;
  background-color: #2568d9;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.offer-card::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}
.offer-card .offer-detail {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.offer-card .offer-detail h4 {
  color: #ffd139;
  font-size: 20px;
  font-weight: 600;
}
.offer-card .offer-detail h4 span {
  font-size: 30px;
  font-weight: 800;
}
.offer-card .offer-detail h6 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400 !important;
}
.offer-btn {
  color: #3c3b8f;
  font-weight: 600;
  border: none;
  background-color: #ffd139;
  padding: 5px 35px;
  border-radius: 6px;
  min-height: 38px;
  margin-top: 10px;
}

/* pdf viewer */
.pdf-file {
  width: 100%;
  height: 100%;
}

.pdf-file #pdf-controls #pdf-download,
.pdf-file #pdf-controls #pdf-toggle-pagination {
  display: none !important;
}

.pdf-file canvas {
  max-height: none !important;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.multiline-ellipsis {
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3 !important; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap !important; /* let the text wrap preserving spaces */
}
.trip_wrapper .MuiPaper-root {
  border-radius: 10px;
}
.trip_wrapper .MuiPaper-root .MuiAlert-icon,
.MuiAlert-message {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.MuiAlert-message .MuiTypography-root {
  margin: 0;
  margin-right: 40px;
}

.exp-trip {
  max-width: 25%;
  margin: 30px auto;
  width: 100%;
}

.exp-trip .MuiPaper-root {
  justify-content: center;
  border-radius: 20px;
  background-color: #cce5ff;
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: rgb(0 79 135);
  color: #fff;
}

.jodit-status-bar .jodit-status-bar__item-right a {
  display: none !important;
}

/* Trip Itinerary */
.trip-form h2 {
  color: #004f87 !important;
  font-size: 24px !important;
}
.trip-form h3 {
  font-size: 24px !important;
}
.trip-form h4 {
  font-size: 20px !important;
}
.trip-form p {
  color: #414141;
  font-size: 20px;
  line-height: 30px;
}

.itinerary-data h1,
.itinerary-details h2,
.itinerary-details h3,
.itinerary-details h4 {
  font-size: 22px !important;
  margin-bottom: 20px;
  color: #004f87;
}
.itinerary-data ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  margin-left: 35px !important;
}
.itinerary-data ul li {
  list-style: disc !important;
}
.trip-form,
.itinerary-details {
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fbfbfb;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.04);
  padding: 20px;
  width: 100%;
}
.trip-form h3 {
  font-size: 24px !important;
}
.trip-form h4 {
  font-size: 20px !important;
}
.trip-form p {
  color: #414141;
  font-size: 20px;
  line-height: 30px;
}
.itinerary-data .custom-textarea {
  width: 60%;
  height: 150px;
  overflow-y: auto;
  padding: 10px;
  border: 2px solid #0a568c;
  border-radius: 4px;
  resize: vertical;
  font-family: Arial, sans-serif;
  font-size: 16px;
  display: block;
  margin: 20px auto 40px;
}
.google-photo {
  max-width: 100%;
  max-height: 400px;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.google-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
